.kata-splash {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 32px;
  background-color: #f6f7f8;
  overflow-y: auto;
  z-index: 10000;
}

.kata-splash__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.kata-splash__error {
  display: none;
}

.kata-splash__error-title {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  font-family: 'Museo Sans Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.kata-splash__error-details {
  margin-bottom: 16px;
  padding: 16px;
  background-color: #e2e6e8;
  border-radius: 4px;
  font-size: 13px;
  line-height: 20px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

.kata-splash__error-description {
  font-size: 13px;
  line-height: 20px;
  max-width: 600px;
  overflow-x: auto;
  font-family: 'Museo Sans Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.kata-splash--hasError .kata-splash__error {
  display: block;
}

.kata-splash--hasError .kata-splash__loading {
  display: none;
}

@font-face {
  font-family: 'Museo Sans Rounded';
  src: url(/static/media/MuseoSansRounded-100.d6a6981d.otf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Museo Sans Rounded';
  src: url(/static/media/MuseoSansRounded-300.a6e4413a.otf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Museo Sans Rounded';
  src: url(/static/media/MuseoSansRounded-500.229592f6.otf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Museo Sans Rounded';
  src: url(/static/media/MuseoSansRounded-700.cdf94ace.otf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Museo Sans Rounded';
  src: url(/static/media/MuseoSansRounded-900.7002ff77.otf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Museo Sans Rounded';
  src: url(/static/media/MuseoSansRounded-1000.76c9c92b.otf);
  font-style: normal;
  font-weight: 1000;
}

@charset "UTF-8";

@font-face {
  font-family: 'kata';
  src: url(/static/media/kata.8c8ca626.eot);
  src: url(/static/media/kata.8c8ca626.eot?#iefix) format('embedded-opentype'), url(/static/media/kata.2bbf5dab.woff) format('woff'),
    url(/static/media/kata.79c1025c.ttf) format('truetype'), url(/static/media/kata.5f531ea1.svg#kata) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

[data-icon]:before {
  font-family: 'kata' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  font-display: auto;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'kata' !important;
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  font-display: auto;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account:before {
  content: '\61';
}

.icon-account-type:before {
  content: '\62';
}

.icon-add:before {
  content: '\63';
}

.icon-add-bold:before {
  content: '\64';
}

.icon-admin:before {
  content: '\65';
}

.icon-analytics:before {
  content: '\66';
}

.icon-api:before {
  content: '\67';
}

.icon-arrow:before {
  content: '\68';
}

.icon-arrow-down:before {
  content: '\69';
}

.icon-arrow-left:before {
  content: '\6a';
}

.icon-arrow-right:before {
  content: '\6b';
}

.icon-audio:before {
  content: '\6c';
}

.icon-bot:before {
  content: '\6d';
}

.icon-building:before {
  content: '\6e';
}

.icon-button:before {
  content: '\6f';
}

.icon-carousel:before {
  content: '\70';
}

.icon-close:before {
  content: '\71';
}

.icon-cms:before {
  content: '\72';
}

.icon-command:before {
  content: '\73';
}

.icon-conversation:before {
  content: '\74';
}

.icon-copy:before {
  content: '\75';
}

.icon-dashboard:before {
  content: '\76';
}

.icon-deployments:before {
  content: '\77';
}

.icon-development:before {
  content: '\78';
}

.icon-dictionary:before {
  content: '\79';
}

.icon-docs:before {
  content: '\7a';
}

.icon-faq:before {
  content: '\41';
}

.icon-fallback:before {
  content: '\42';
}

.icon-error-log:before {
  content: '\43';
}

.icon-error:before {
  content: '\44';
}

.icon-environments:before {
  content: '\45';
}

.icon-entity:before {
  content: '\46';
}

.icon-email:before {
  content: '\47';
}

.icon-enabled-modules:before {
  content: '\48';
}

.icon-edit:before {
  content: '\49';
}

.icon-dynamic-carousel:before {
  content: '\4a';
}

.icon-dropdown:before {
  content: '\4b';
}

.icon-drag:before {
  content: '\4c';
}

.icon-download:before {
  content: '\4d';
}

.icon-forms:before {
  content: '\4e';
}

.icon-image-map:before {
  content: '\4f';
}

.icon-image:before {
  content: '\50';
}

.icon-img-uploader:before {
  content: '\51';
}

.icon-info:before {
  content: '\52';
}

.icon-invoice:before {
  content: '\53';
}

.icon-location:before {
  content: '\54';
}

.icon-location-cms:before {
  content: '\55';
}

.icon-locator:before {
  content: '\56';
}

.icon-log:before {
  content: '\57';
}

.icon-method:before {
  content: '\58';
}

.icon-methods:before {
  content: '\59';
}

.icon-modules:before {
  content: '\5a';
}

.icon-refresh:before {
  content: '\30';
}

.icon-production:before {
  content: '\31';
}

.icon-product:before {
  content: '\32';
}

.icon-pricing:before {
  content: '\33';
}

.icon-phrase:before {
  content: '\34';
}

.icon-password:before {
  content: '\35';
}

.icon-pages:before {
  content: '\36';
}

.icon-overview:before {
  content: '\37';
}

.icon-overal-analytic:before {
  content: '\38';
}

.icon-ok:before {
  content: '\39';
}

.icon-nlus:before {
  content: '\21';
}

.icon-nlu:before {
  content: '\22';
}

.icon-more:before {
  content: '\23';
}

.icon-remove:before {
  content: '\24';
}

.icon-remove-tag:before {
  content: '\25';
}

.icon-rollback:before {
  content: '\26';
}

.icon-save:before {
  content: '\27';
}

.icon-schedule:before {
  content: '\28';
}

.icon-settings:before {
  content: '\29';
}

.icon-settings-cms:before {
  content: '\2a';
}

.icon-slider:before {
  content: '\2b';
}

.icon-staging:before {
  content: '\2c';
}

.icon-sticker:before {
  content: '\2d';
}

.icon-subscription:before {
  content: '\2e';
}

.icon-success:before {
  content: '\2f';
}

.icon-tapescript:before {
  content: '\3a';
}

.icon-test-chatbot:before {
  content: '\3b';
}

.icon-test-nlu:before {
  content: '\3c';
}

.icon-text:before {
  content: '\3d';
}

.icon-text-data:before {
  content: '\3e';
}

.icon-textarea:before {
  content: '\3f';
}

.icon-textbox:before {
  content: '\40';
}

.icon-textlist:before {
  content: '\5b';
}

.icon-tick:before {
  content: '\5d';
}

.icon-toggle:before {
  content: '\5e';
}

.icon-train:before {
  content: '\5f';
}

.icon-trait:before {
  content: '\60';
}

.icon-trash:before {
  content: '\7b';
}

.icon-training:before {
  content: '\7c';
}

.icon-warning:before {
  content: '\7d';
}

.icon-view:before {
  content: '\7e';
}

.icon-videos:before {
  content: '\5c';
}

.icon-video:before {
  content: '\e000';
}

.icon-version-lists:before {
  content: '\e001';
}

.icon-users:before {
  content: '\e002';
}

.icon-usage:before {
  content: '\e003';
}

.icon-update:before {
  content: '\e004';
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(/static/media/SFPRODISPLAYREGULAR.aaeac71d.OTF);
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(/static/media/SFPRODISPLAYBOLD.644563f4.OTF);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-Thin.603fb2d8.otf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-ThinItalic.df5de52b.otf);
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-Ultralight.5e3e5fd7.otf);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-UltralightItalic.f13eca0f.otf);
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-Light.753fc9d4.otf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-LightItalic.28d8d0b1.otf);
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-Regular.a07152f3.otf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-RegularItalic.b1be36c4.otf);
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-Medium.ded5efe3.otf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-MediumItalic.499ca95e.otf);
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-Semibold.9a7c849b.otf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-SemiboldItalic.919f7cd0.otf);
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-Bold.88b6e0b2.otf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-BoldItalic.41a00af0.otf);
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-Heavy.fadde340.otf);
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-HeavyItalic.a6e4377c.otf);
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-Black.82d5f385.otf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url(/static/media/SF-Pro-Text-BlackItalic.774fc2cd.otf);
  font-style: italic;
  font-weight: 900;
}

