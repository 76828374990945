@font-face {
  font-family: 'SF Pro Display';
  src: url('./files/SFPRODISPLAYREGULAR.OTF');
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./files/SFPRODISPLAYBOLD.OTF');
  font-style: normal;
  font-weight: 700;
}
